body {
  font-size: 12px;
  font-family: SegoeUIRegular;
  overflow: hidden;

  @media screen and (min-width: $laptop-l) {
    font-size: 16px;
  }

  //@media screen and (max-width: $laptop-s) {
  //  font-size: 7px;
  //}
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {

  }
}

input, button {
  outline: none;
}

::selection {
  background: rgba(0, 0, 0, 0.1);
  //color: #FFF;
}

#warning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #333;
  color: #fff;
  display: none;
  z-index: 99999999;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 1.25em;
    width: 100%;
    padding: 0 1em;
    text-align: center;
  }
}