.map-opened >.t-table > .t-td.right-block {
  //width: 30%;
  vertical-align: top;
  background: $blue-bg;

  .inner {
    padding: 2em;
    height: 100vh;
    color: #fff;
    position: relative;

    .desc-first{
      transition: 1s;
    }
    .desc-second{
      display: none;
      transition: 1s;
    }
    &.region-info {
      &.mini {
        padding: 2em 1em;
        .desc-first{
          display: none;
        }
        .desc-second{
          display: block;
        }
      }
    }

    .copyright {
      position: absolute;
      bottom: 2em;
      left: 2em;
      right: 2em;
    }

    &.mini {
      .copyright {
        left: 1em;
        right: 1em;
      }
    }
  }

  .close-btn {
    cursor: pointer;
    display: table;

    &:hover {
      span:last-child {
        text-decoration: underline;
      }
    }

    span {
      display: inline-block;
      vertical-align: middle;
      //font-size: .875em;

      &:last-child {
        padding-left: .5em;
      }
    }

    .icon-left {
      background: #fff;
      border-radius: 50%;
      line-height: 1;
      color: $blue-bg;
      padding: .5em;
      font-size: .875em;
    }
  }

  .all-regions .close-btn {
    visibility: hidden;
  }

  h2 {
    font-size: 2em;
    font-family: SegoeUIBlack;
    text-transform: uppercase;
    margin: 1em 0;
  }

  p,
  ul a {
    font-size: 1.125em;
  }

  .choose-title,
  .desc {
    margin-bottom: 1em;

    strong {
      font-family: SegoeUISemiBold;
      font-weight: normal;
    }
  }

  ul.list-primary {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 1em;

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
      //border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      //border-radius: 5px;
    }

    li {
      position: relative;

      a {
        padding: .3em 0;
        padding-left: 2em;
        display: table;
        position: relative;

        &.active {
          pointer-events: none;
        }

        &:hover, &.hovered {
          &:before {
            border-width: 3px;
          }
        }
      }

      a:before {
        content: '';
        position: absolute;
        left: 0;
        top: .3em;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        border: .33em solid $circle-border;
      }

      //&.akm {
      //  a:before {
      //    background: $akm;
      //  }
      //}
      //&.akt {
      //  a:before {
      //    background: $akt;
      //  }
      //}
      //&.alm {
      //  a:before {
      //    background: $alm;
      //  }
      //}
      //&.aty {
      //  a:before {
      //    background: $aty;
      //  }
      //}
      //&.vos {
      //  a:before {
      //    background: $vos;
      //  }
      //}
      //&.zha {
      //  a:before {
      //    background: $zha;
      //  }
      //}
      //&.zap {
      //  a:before {
      //    background: $zap;
      //  }
      //}
      //&.kar {
      //  a:before {
      //    background: $kar;
      //  }
      //}
      //&.kos {
      //  a:before {
      //    background: $kos;
      //  }
      //}
      //&.kyz {
      //  a:before {
      //    background: $kyz;
      //  }
      //}
      //&.man {
      //  a:before {
      //    background: $man;
      //  }
      //}
      //&.pav {
      //  a:before {
      //    background: $pav;
      //  }
      //}
      //&.sev {
      //  a:before {
      //    background: $sev;
      //  }
      //}
      //&.juz {
      //  a:before {
      //    background: $juz;
      //  }
      //}
    }

    &.white {
      li {
        a:before {
          background: #fff;
          border-color: #272c3d;
        }
      }
    }

    &.orange {
      li {
        a:before {
          background: $main-color;
          border-color: #3c1000;
        }
      }
    }
  }
}