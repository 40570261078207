.map-opened {
  overflow: hidden;

  &>.t-table {
    height: 100vh;
    table-layout: fixed;

    &>.t-td {
      vertical-align: middle;
      padding: 0 15px;
      transition: .3s linear;
    }
  }
}

.full-map {
  position: relative;

  &.selected {
    #map {
      path {
        //fill: #c3c3c3;
      }

      g .count {
        fill: #666;
      }
    }
  }

  #map {
    width: 100%;
    -webkit-filter: drop-shadow(-20px 20px 15px rgba(0, 0, 0, 0));
    filter: drop-shadow(-20px 20px 15px rgba(0, 0, 0, 0));

    path {
      stroke: #d1d1d1;
      cursor: pointer;
      stroke-width: 0;
      fill: rgba(0,0,0,.5);
      //transition: .3s;
    }

    g {
      &:hover, &.hovered {
        cursor: pointer;

        path {
          stroke-width: 1px;
          fill: rgba(0,0,0,.2);
          stroke: #c3c3c3;
        }
      }

      &.active {
        path {
          fill: rgba(0,0,0,.65) !important;
          stroke-width: 1px;
        }

        .count {
          opacity: 0;
        }

        image {
          opacity: 1;

          &.hovered {
            opacity: 1;
          }
        }

        a {
          pointer-events: none;
        }
      }

      image {
        opacity: 0;
        //opacity: .5;
        transition: .3s;
        width: 20px;
        height: 20px;
        transform: translate(7px, 374px);
      }

      .count {
        font-family: SegoeUIBlack;
        font-style: italic;
        fill: $main-color;
        transition: .3s;
        display: none;
      }

      .city-name {
        fill: #fff;
        font-size: .7em;
        transition: .3s;
        cursor: pointer;
        -moz-transform: translateX(-120px);
        //display: none;

        @media screen and (max-width: $laptop-l) {
          font-size: 1em;
        }

        @media screen and (max-width: $laptop-s) {
          font-size: 1.5em;
        }

        &.active {
          //opacity: 0;
          //display: none;
          pointer-events: none;
        }
      }

      //&#akm {
      //  .count {
      //    fill: $akm;
      //  }
      //}
      //&#akt {
      //  .count {
      //    fill: $akt;
      //  }
      //}
      //&#alm {
      //  .count {
      //    fill: $alm;
      //  }
      //}
      //&#aty {
      //  .count {
      //    fill: $aty;
      //  }
      //}
      //&#vos {
      //  .count {
      //    fill: $vos;
      //  }
      //}
      //&#zha {
      //  .count {
      //    fill: $zha;
      //  }
      //}
      //&#zap {
      //  .count {
      //    fill: $zap;
      //  }
      //}
      //&#kar {
      //  .count {
      //    fill: $kar;
      //  }
      //}
      //&#kos {
      //  .count {
      //    fill: $kos;
      //  }
      //}
      //&#kyz {
      //  .count {
      //    fill: $kyz;
      //  }
      //}
      //&#man {
      //  .count {
      //    fill: $man;
      //  }
      //}
      //&#pav {
      //  .count {
      //    fill: $pav;
      //  }
      //}
      //&#sev {
      //  .count {
      //    fill: $sev;
      //  }
      //}
      //&#juz {
      //  .count {
      //    fill: $juz;
      //  }
      //}
    }
  }

  //div.city-name {
  //  color: #fff;
  //  font-size: 1.35em;
  //  transition: .3s;
  //  cursor: pointer;
  //  display: none;
  //
  //  &.active {
  //    opacity: 0;
  //    z-index: -1;
  //  }
  //}
}