.button-primary {
  white-space: nowrap;
  padding: 1em;
  color: $blue;
  border: 2px solid $blue;
  font-family: SegoeUISemiBold;
  font-size: 1.125em;
  border-radius: .3em;
  transition: .3s;
  display: inline-block;
  text-align: center;

  &:hover {
    background: $blue;
    color: #fff;
  }

  span {
    display: inline-block;
    vertical-align: middle;

    &.icon {
      color: $yellow;
      font-size: 1.5em;
      margin-right: .3em;
    }
  }
}