.map-opened >.t-table > .t-td.left-block {
  //background: url('/assets/img/map-bg.jpg') center center;//
  background: url(/assets/img/bg-map.JPG) center center no-repeat, #000;
  background-size: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.1);
  }

  //.region-map {
  //  display: none;
  //
  //  img {
  //    display: block;
  //    margin: 0 auto;
  //    -webkit-filter: $map-shadow;
  //    filter: $map-shadow;
  //    max-width: 100%;
  //  }
  //}

  .h-main {
    color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2em;
    text-transform: uppercase;
    font-family: SegoeUIBlack;
    z-index: 6;

    @media screen and (max-width: $tablet) {
      display: none;
    }
  }

  .h-first {
    top: 1em;
  }

  .h-second {
    bottom: 1em;
  }

  video {
    position: absolute;
    left:0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: #000;
    @include object-fit(cover);

    @media screen and (max-width: $tablet) {
      display: none;
    }
  }

  video::-webkit-media-controls {
    display:none !important;
  }

  *::-webkit-media-controls-panel {
    display: none!important;
    -webkit-appearance: none;
  }

  /* Old shadow dom for play button */

  *::-webkit-media-controls-play-button {
    display: none!important;
    -webkit-appearance: none;
  }

  /* New shadow dom for play button */

  /* This one works! */

  *::-webkit-media-controls-start-playback-button {
    display: none!important;
    -webkit-appearance: none;
  }
}