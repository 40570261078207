.top-bar {
  display: table;
  width: 100%;

  .close-btn {
    float: left;
    padding: .25em 0;
  }

  .lang-buttons {
    float: right;

    a {
      display: inline-block;
      padding: 0.3em 1em;
      border: 2px solid #fff;
      border-radius: 1.4em;
      text-transform: uppercase;

      &.active {
        background: #fff;
        color: $blue-bg;
      }
    }
  }
}