//Размеры экранов
$laptop-l: 1600px;
$laptop-m: 1200px;
$laptop-s: 992px;
$tablet: 767px;
$mobile-l: 480px;
$mobile-m: 375px;
$mobile-s: 320px;

//colors
$blue-bg: #01071b;

//city-colors
$akm: #ff4546;
$akt: #217fbc;
$alm: #1aa758;
$aty: #ec9e00;
$vos: #f96908;
$zha: #fcae88;
$zap: #02a9a2;
$kar: #8f3fae;
$kos: #f11a77;
$kyz: #3de14e;
$man: #7ffef3;
$pav: #c0a2a1;
$sev: #b6ba01;
$juz: #948d61;

//main-city-color
$main-color: #ffd050;

$circle-border: #352b11;

//text-color
$blue: #6291fd;
$yellow: #ffd050;
$grey: #bebebe;

$map-shadow: drop-shadow(-20px 20px 15px rgba(0, 0, 0, .4));