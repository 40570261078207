.map-opened > .t-table > .t-td.place-info {
  width: 0;
  overflow: hidden;
  padding: 0;
  opacity: 0;
  transition: .3s;
  vertical-align: top;

  &>.inner {
    max-height: 100vh;
    @include overflow();

    .tour {
      position: relative;

      &:before {
        //content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.4);
      }

      iframe {
        width: 100%;
        height: 20em;
      }

      img {
        width: 100%;
        height: 20em;
        object-fit: cover;
      }

      h4 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 6em;
        color: #fff;
        opacity: .8;
        text-transform: uppercase;
        font-family: SegoeUIBold;
        text-align: center;
        margin: 0;

        img {
          width: .8em;
          height: auto;
        }
      }

      a:hover {
        h4 {
          opacity: 1;
        }
      }
    }

    .detailed-info {
      padding: 3em;

      .table-outer {
        margin: -2em;

        table {
          border-spacing: 2em;
          border-collapse: separate;
          width: 100%;
          table-layout: fixed;

          td {
            box-shadow: 0 0 50px rgba(0, 0, 0, .1);
            vertical-align: top;
            border-collapse: collapse;

            &.avatar {
              width: 50%;

              .image {
                img {
                  width: 100%;
                  height: 30em;
                  object-fit: cover;
                }
              }

              .options {
                padding: 2em;

                h3 {
                  font-family: SegoeUIBold;
                  font-size: 2em;
                  margin-top: 0;
                }

                .region {
                  color: $grey;
                  font-size: 1.125em;
                }

                //.row>* {
                //  display: inline-block;
                //  width: calc(33% - 4px);
                //  padding: 0 15px;
                //
                //  @media screen and (max-width: $laptop-l) {
                //    padding: 0 5px;
                //  }
                //
                //  a {
                //    width: 100%;
                //    cursor: pointer;
                //
                //    &.active {
                //      background: $blue;
                //      color: #fff;
                //    }
                //  }
                //}

                .rate {
                  position: relative;
                  text-align: right;

                  .rating {
                    font-size: 2em;
                  }

                  .title {
                    position: absolute;
                    bottom: 100%;
                    right: 15px;
                    font-size: 1.125em;
                    font-family: SegoeUISemiBold;
                    color: $blue;
                  }
                }
              }
            }

            &.text {
              width: 50%;

              .inner {
                padding: 2em;
                max-height: calc(100vh - 26em);
                @include overflow();

                .title {
                  display: table;
                  width:100%;
                  //margin-bottom: 2em;
                  margin-bottom: 1em;

                  &>div {
                    //display: table-cell;
                    //vertical-align: top;

                    h3 {
                      font-family: SegoeUIBold;
                      font-size: 2em;
                      margin-top: 0;
                    }

                    .region {
                      color: $grey;
                      font-size: 1.125em;
                    }

                    .rating {
                      font-size: 3em;

                      input {
                        display: none;

                        &+label {
                          color: $grey;
                          cursor: pointer;
                        }

                        &:checked {
                          &+label span {
                            color: $yellow;
                          }
                        }
                      }
                    }

                    //&:last-child {
                    //  width: 1%;
                    //  padding-left: 1em;
                    //}
                  }
                }

                .plain-text {
                  font-size: 1.125em;
                }
              }
            }

            &.gallery {
              padding: 1em;

              .title {
                display: table;
                width: 100%;
                margin-bottom: 1em;

                &>div {
                  display: table-cell;
                }

                h3 {
                  margin-top: 0;
                  font-family: SegoeUISemiBold;
                  font-size: 1.125em;
                  color: $blue;
                  margin-bottom: 0;
                }

                .controls {
                  width: 1%;
                  white-space: nowrap;

                  span {
                    display: inline-block;
                    cursor: pointer;
                    color: $blue;

                    &:hover {
                      color: $yellow;
                    }
                  }
                }
              }
            }

            &.photo {
              //width: 67%;

              .slick-slide {
                outline: none !important;
              }

              .row {
                margin: 0 -7.5px;
              }

              .list div .col-sm-12 {
                padding: 0 7.5px;

                a {
                  display: block;

                  &:not(:last-child) {
                    margin-bottom: 15px;
                  }
                }

                img {
                  height: 5em;
                  width: 100%;
                  object-fit: cover;
                }
              }
            }

            &.video {
              //width: 33%;

              a {
                img {
                  width: 100%;
                  margin: 0 auto;
                  display: block;
                  height: calc(15em + 30px);
                  @include object-fit(cover);
                }
              }

              .list > span {
                display: none;
              }

              .yt {
                padding: 2em 0 1em;
                display: none;

                img {
                  display: block;
                  margin: 1em auto 0;
                  width: 100%;
                  max-width: 150px;
                }
              }

              .inner-map{
                width: 100%;
                height: 17em;
              }
            }

            .inner-map{
              width: 100%;
              height: 17em;
            }
          }
        }
      }
    }
  }
}

.video-modal {
  padding: 0 !important;
  padding-bottom: 39px !important;
  background: transparent !important;

  video {
  }

  .video-controls {
    position: absolute;
    bottom: 0;
    z-index: 99999999999;
    display: table;
    width: 100%;
    left: 0;

    &>* {
      float: left;
      height: 44px;
    }

    input[type=range] {
      -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
      //width: 100%; /* Specific width is required for Firefox. */
      background: rgba(30,30,30,.6);

      &:focus {
        //background: rgba(0,0,0,.8);
      }
    }

    input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    input[type=range]:focus {
      outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
    }

    input[type=range]::-ms-track {
      width: 100%;
      cursor: pointer;

      /* Hides the slider so custom styles can be added */
      background: rgba(30,30,30,.6);
      border-color: transparent;
      color: transparent;
    }

    /* Special styling for WebKit/Blink */
    input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: 1px solid #333;
      height: 24px;
      width: 8px;
      background: #fff;
      cursor: pointer;
      margin-top: -9.4px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    }

    /* All the same stuff for Firefox */
    input[type=range]::-moz-range-thumb {
      height: 24px;
      width: 5px;
      background: #ffffff;
      cursor: pointer;
    }

    /* All the same stuff for IE */
    input[type=range]::-ms-thumb {
      height: 24px;
      width: 5px;
      background: #ffffff;
      cursor: pointer;
    }

    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 5px;
      cursor: pointer;
      background: #777;
    }

    input[type=range].active::-webkit-slider-runnable-track {
      background: #c3c3c3;
    }

    input[type=range]::-moz-range-track {
      width: 100%;
      height: 8.4px;
      cursor: pointer;
      box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      background: rgba(0,0,0,.3);
      border-radius: 1.3px;
      border: 0.2px solid #010101;
    }

    input[type=range]::-ms-track {
      width: 100%;
      height: 8.4px;
      cursor: pointer;
      background: rgba(0,0,0,.4);
      border-color: transparent;
      border-width: 16px 0;
      color: transparent;
    }
    input[type=range]::-ms-fill-lower {
      background: #777;
      border: 0.2px solid #010101;
      border-radius: 2.6px;
      box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    }
    input[type=range]:focus::-ms-fill-lower {
      background: #777;
    }
    input[type=range]::-ms-fill-upper {
      background: #777;
      border: 0.2px solid #010101;
      border-radius: 2.6px;
      box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    }
    input[type=range]:focus::-ms-fill-upper {
      background: #777;
    }

    button {
      width: 44px;
    }

    .seek-bar {
      width: calc((100% - 44px*3)*7 / 8);
    }

    .volume-bar {
      width: calc((100% - 44px*3) / 8);
    }
  }

  video:-webkit-full-screen {
    &+.video-controls {
      display: none !important;
    }
  }

  button.fancybox-close-small {
    display: none;
  }

  button.fancybox-button--close {
    position: fixed;
    top: 0;
    right: 0;
  }

  video:not(:-webkit-full-screen) {
    &::-webkit-media-controls-panel {
      display: none!important;
      -webkit-appearance: none;
    }

    /* Old shadow dom for play button */

    &::-webkit-media-controls-play-button {
      display: none!important;
      -webkit-appearance: none;
    }

    /* New shadow dom for play button */

    /* This one works! */

    &::-webkit-media-controls-start-playback-button {
      display: none!important;
      -webkit-appearance: none;
    }
  }
}