.rate {
  display: table;
  width: 100%;
  border-spacing: 0;

  .title {
    margin-left: .5em;
  }

  &>* {
    display: inline-block;
    vertical-align: middle;
  }

  &>span {
    display: inline-block;
    vertical-align: middle;

    &:first-child {
      margin-right: 1em;
    }

    &:last-child {
      font-size: 2em;
    }
  }

  .rating {
    overflow: hidden;
    display: inline-block;
    position: relative;

    .rating-input {
      position: absolute;
      opacity: 0;
      z-index: -9;
      display: none;
    }
  }
  .rating:hover .rating-star:hover,
  .rating:hover .rating-star:hover ~ .rating-star,
  .rating-input:checked ~ .rating-star {
    color: $yellow;
  }
  .rating-star,
  .rating:hover .rating-star {
    position: relative;
    float: right;
    display: block;
    margin-bottom: 0;
    padding: 0 .1em;
    cursor: pointer;
    color: $grey;
  }
}