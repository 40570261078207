//Свойство Object-fit
@mixin object-fit($fit: fill, $position: null){
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

//Левый и правый margin внешнего блока = border-spacing'у внутреннего блока
@mixin table($margin) {
  margin: 0 (-$margin);

  &>div {
    border-spacing: $margin 0;
  }
}

//Свойства display и width для экранов размера ниже 1200px
@mixin display-m($display-m, $width-m: auto) {
  @media screen and (max-width: $laptop-m) {
    width: $width-m;
    display: $display-m;
  }
}

//Свойства display и width для экранов размера ниже 992px
@mixin display-s($display-s, $width-s: auto) {
  @media screen and (max-width: $laptop-s) {
    width: $width-s;
    display: $display-s;
  }
}

//Свойства display и width для экранов размера ниже 767x
@mixin display-xs( $display-xs, $width-x: auto) {
  @media screen and (max-width: $tablet) {
    width: $width-xs;
    display: $display-xs;
  }
}

@mixin overflow() {
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    background-color: #cfcfcf;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #cfcfcf;
    //border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $blue-bg;
    //border-radius: 5px;
  }
}